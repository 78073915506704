import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../components/BackButtonHeader";
import DPCalc from "../components/DPCalc";

function dprimeCalc(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | d' Calculator</title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="d' Calculator" />
      <DPCalc />
    </div>
  );
}

export default dprimeCalc;
